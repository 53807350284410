
/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* @layer base {
    @font-face {
      font-family: Proxima Nova;
      font-weight: 400;
      src: url(/fonts/proxima-nova/400-regular.woff) format("woff");
    }
    @font-face {
      font-family: Proxima Nova;
      font-weight: 500;
      src: url(/fonts/proxima-nova/500-medium.woff) format("woff");
    }
  } */